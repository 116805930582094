import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content.js'
import Layout from '../components/Layout.js'
import Accordion from '../components/Accordion'
import BackgroundVideo from '../components/BackgroundVideo'
import Gallery from '../components/Gallery'
// import Popup from '../components/Popup'

// Export Template for use in CMS preview
export const SobrePageTemplate = ({
  title,
  subtitle,
  featuredImage,
  section1,
  section2,
  video,
  videoPoster,
  videoTitle,
  accordion,
  body,
  gallery
}) => (
  <main>
    <PageHeader
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />
    <section className="section">
      <div className="container">
        <Content source={section1} />
      </div>
    </section>
    {gallery.length > 0 && (
      <section className="section">
        <div className="container">
          <h2>Our gallery component</h2>
          <Gallery images={gallery} />
        </div>
      </section>
    )}

    <section className="section">
      <div className="container">
        <Content source={section2} />
      </div>
    </section>

    {video && (
      <section className="BackgroundVideo-section section">
        <BackgroundVideo poster={videoPoster} videoTitle={videoTitle}>
          {video && <source src={video} type="video/mp4" />}
        </BackgroundVideo>
      </section>
    )}

    {accordion && (
      <section className="section">
        <div className="container">
          <Accordion items={accordion} />
        </div>
      </section>
    )}

    {/* <section className="section">
      <div className="container">
        <Popup>
          <Content source={section1} />
        </Popup>
      </div>
    </section> */}
  </main>
)

const SobrePage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <SobrePageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default SobrePage

export const pageQuery = graphql`
  query SobrePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Gallery
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
        section1
        section2
        video
        videoPoster
        videoTitle
      }
    }
  }
`
